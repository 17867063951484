
import Vue from 'vue';
import Component from 'vue-class-component';
@Component({
  name: 'Home'
})
export default class Home extends Vue {

  created(){
    
  }
}
